import Button from 'components/v2/atomic/button/Button';
import Card from 'components/v2/atomic/card/Card';
import Icon, { IconNames } from 'components/v2/atomic/icon/Icon';
import StatusIndicator from 'components/v2/atomic/statusIndicator/StatusIndicator';
import { useTranslation } from 'react-i18next';

export interface EventsCardProps {
  id: string;
  heading: string;
  includeOpenRsvpTag?: boolean;
  date: string;
  time: string;
  locationType: 'online' | 'in-person';
  location?: string;
  height?: string;
  handleViewDetailsClick?: () => void;
}

const EventsCard = ({
  heading,
  includeOpenRsvpTag = false,
  date,
  time,
  locationType,
  location,
  handleViewDetailsClick,
  id,
  height
}: EventsCardProps) => {
  const { t } = useTranslation('slices');

  return (
    <Card id={id} sxOverride={{ height }}>
      {includeOpenRsvpTag && (
        <StatusIndicator
          id="openRsvpTag"
          text={t('OPEN_RSVP')}
          intent="success"
          type="tag"
          data-testid={`${id}-openRsvp-tag`}
        />
      )}
      <h3
        sx={{
          mt: includeOpenRsvpTag ? '16px' : 0,
          mb: '16px 0',
          lineHeight: 'medium'
        }}
        data-testid={`${id}-heading`}
      >
        {heading}
      </h3>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
          mb: '24px',

          '>div': {
            display: 'flex',
            columnGap: '12px',
            alignItems: 'top'
          }
        }}
      >
        <div className="eventDate">
          <Icon icon={IconNames.CALENDAR} sx={{ mt: '2px' }} />
          <span data-testid={`${id}-date`}>{date}</span>
        </div>
        <div className="eventTime">
          <Icon icon={IconNames.CLOCK} sx={{ mt: '2px' }} />
          <span data-testid={`${id}-time`}>{time}</span>
        </div>
        <div className="eventLocation">
          <Icon
            icon={
              locationType === 'online'
                ? IconNames.ILOCATION
                : IconNames.LOCATION
            }
            sx={{ mt: '2px' }}
          />
          <span data-testid={`${id}-location`}>
            {locationType === 'online' ? t('WEBINAR') : location}
          </span>
        </div>
      </div>
      <Button
        id={`${id}-viewDetails-button`}
        color="blue"
        variant="outline"
        data-testid={`${id}-register-button`}
        onClick={handleViewDetailsClick}
      >
        {t('VIEW_DETAILS')}
      </Button>
    </Card>
  );
};

export default EventsCard;
