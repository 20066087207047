import Banner from 'components/v2/slices/banner/Banner';
import LinkButton from 'components/v2/atomic/linkButton/LinkButton';
import ColumnsText from 'components/v2/slices/columnsText/ColumnsText';
import EventsCard from 'components/v2/slices/eventsCard/EventsCard';
import TwoColumnWithMedia from 'components/v2/slices/twoColumnWithMedia/TwoColumnWithMedia';
import DownloadCard from 'components/v2/slices/downloadCard/DownloadCard';
import Divider from 'components/v2/atomic/divider/Divider';
import Tooltip from 'components/v2/atomic/tooltip/Tooltip';
import { snakeCase } from 'lodash';
import clientSide from 'utils/logger/client-side';
import Card from 'components/v2/atomic/card/Card';
import {
  TypeColumnsText,
  TypeTwoColumnWithMedia,
  TypeBanner,
  TypeDownloadCard,
  TypeText,
  TypeGridContainer,
  TypeDivider,
  TypeCard,
  TypeTooltip,
  TypeIcon,
  TypeSectionContainer,
  TypeTable,
  TypeLinkButton,
  TypeDrawer,
  TypeAccordion,
  TypeWorkshopAndWebinarSection,
  TypeCustomNavigationTabSection,
  TypeSpacer
} from 'types/contentful-types';
import Icon from 'components/v2/atomic/icon/Icon';
import Spacer from 'components/v2/slices/spacer/Spacer';
import {
  TextSlice,
  GridContainerSlice,
  SectionContainer,
  Table,
  DynamicDrawerSlice,
  AccordionSlice,
  WorkshopAndWebinarSlice,
  CustomNavigationTabSection
} from './sliceFactoryComponents/sliceFactoryComponents';

type SliceType =
  | 'twoColumnWithMedia'
  | 'columnsText'
  | 'event'
  | 'banner'
  | 'downloadCard'
  | 'text'
  | 'gridContainer'
  | 'divider'
  | 'card'
  | 'tooltip'
  | 'icon'
  | 'sectionContainer'
  | 'table'
  | 'linkButton'
  | 'drawer'
  | 'accordion'
  | 'workshopAndWebinarSection'
  | 'customNavigationTabSection'
  | 'spacer';

const sliceMap: Record<SliceType, React.FC<any>> = {
  twoColumnWithMedia: TwoColumnWithMedia,
  event: EventsCard,
  columnsText: ColumnsText,
  banner: Banner,
  downloadCard: DownloadCard,
  text: TextSlice,
  gridContainer: GridContainerSlice,
  divider: Divider,
  card: Card,
  tooltip: Tooltip,
  icon: Icon,
  sectionContainer: SectionContainer,
  table: Table,
  linkButton: LinkButton,
  drawer: DynamicDrawerSlice,
  accordion: AccordionSlice,
  workshopAndWebinarSection: WorkshopAndWebinarSlice,
  customNavigationTabSection: CustomNavigationTabSection,
  spacer: Spacer
};

const fieldMap: Record<SliceType, Function> = {
  twoColumnWithMedia: (slice: TypeTwoColumnWithMedia<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    description: slice.fields.description,
    callToAction: slice.fields.ctaText,
    buttonStyle: slice.fields.ctaStyle,
    buttonIcon: slice.fields.ctaIcon,
    // slice.fields.image may be null or undefined but we're confident that when it's not null or undefined, it contains an object with properties we expect, such as 'fields.file.url'
    // @ts-ignore
    imageSource: `https:${slice.fields.image.fields.file.url}`,
    // slice.fields.image may be null or undefined but when it's not null or undefined, it contains an object with properties we expect, such as 'fields.title'
    // @ts-ignore
    imageAlt: slice.fields.imageAlt ?? '',
    imageFit: slice.fields.imageFit,
    imageVerticalAlignment: slice.fields.imageVerticalAlign,
    imageHorizontalAlignment: slice.fields.imageHorizontalAlignment,
    imageOnLeft: slice.fields.imageOnLeft,
    callToActionLink: slice.fields.ctaLink,
    copyBackgroundColour: slice.fields.backgroundColour
  }),
  columnsText: (slice: TypeColumnsText<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    headerType: slice.fields.headerType,
    columnContent: [
      {
        header: slice.fields.header1,
        paragraph: slice.fields.content1
      },
      {
        header: slice.fields.header2,
        paragraph: slice.fields.content2
      },
      {
        header: slice.fields.header3,
        paragraph: slice.fields.content3
      }
    ].filter(content => content.header && content.paragraph)
  }),
  event: (slice: any) => ({
    id: snakeCase(slice.fields.title),
    heading: slice.fields.title,
    eventDetails: slice.fields.eventDetails,
    includeOpenRsvpTag: slice.fields.isOpenRSVP,
    date: slice.fields.date,
    time: slice.fields.time,
    locationType: slice.fields.locationType,
    location:
      slice.fields.locationType === 'in-person'
        ? slice.fields.address
        : slice.fields.webinarLink
  }),
  banner: (slice: TypeBanner<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    bannerTitle: slice.fields.bannerTitle,
    copyBackgroundColour: slice.fields.copyBackgroundColour,
    showOverlayGraphic: slice.fields.showOverlayGraphic,
    // backgroundImage may be null or undefined but when it's not null or undefined, it contains 'fields.file.url'
    // @ts-ignore
    backgroundImageUrl: `https:${slice.fields.backgroundImage?.fields.file.url}`,
    backgroundPositionX: slice.fields.backgroundPositionX,
    backgroundPositionY: slice.fields.backgroundPositionY,
    // slice.fields.card may be null or undefined but when it's not null or undefined, it contains at least one element
    // @ts-ignore
    cardProps: slice.fields.card ? slice.fields.card[0].fields : null,
    cardPosition: slice.fields.cardPosition,
    captionText: slice.fields.captionText,
    captionColour: slice.fields.captionColour,
    isMainBanner: slice.fields.isMainBanner
  }),
  downloadCard: (slice: TypeDownloadCard<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    description: slice.fields.description,
    englishUrl: {
      // slice.fields.englishUrl may be null or undefined, but when it's not null or undefined, it's an object containing properties we expect, such as 'fields.file.url'
      // @ts-ignore
      url: `https:${slice.fields.englishUrl?.fields.file.url}`,
      fileSize: slice.fields.englishFileSize
    },
    fileType: slice.fields.englishFileType,
    optionalTag: slice.fields.optionalTag,
    additionalText: slice.fields.additionalText,
    width: slice.fields.widthDesktopView,
    widthMobileView: slice.fields.widthMobileView,
    ...(slice.fields.frenchUrl
      ? {
          frenchUrl: {
            // slice.fields.frenchUrl may be null or undefined, but when it's not null or undefined, it's an object containing properties we expect, such as 'fields.file.url'
            // @ts-ignore
            url: `https:${slice.fields.frenchUrl.fields.file.url}`,
            fileSize: slice.fields.frenchFileSize
          }
        }
      : {})
  }),
  text: (slice: TypeText<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    headline: slice.fields.headline,
    description: slice.fields.description,
    HeaderType: slice.fields.HeaderType
  }),
  gridContainer: (slice: TypeGridContainer<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    content: slice.fields.content,
    gridFormat: slice.fields.gridFormat,
    hasPadding: slice.fields.hasPadding ?? true
  }),
  divider: (slice: TypeDivider<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    vertical: slice.fields.vertical,
    condensed: slice.fields.condensed,
    borderStyle: slice.fields.borderStyle,
    borderWidth: slice.fields.borderWidth
  }),
  card: (slice: TypeCard<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    accent: slice.fields.accent,
    title: slice.fields.title,
    noPadding: slice.fields.noPadding,
    accentColor: slice.fields.accentColor,
    description: slice.fields.description,
    // This reason being existing components don't have this field so if component already exists
    // we want to default it to false.
    hasTitleDecoration: slice.fields.hasTitleDecoration ?? false,
    titleDecorationText: slice.fields.titleDecorationText,
    titleDecorationIcon: slice.fields.titleDecorationIcon,
    backgroundColor: slice.fields.backgroundColor ?? 'white'
  }),
  tooltip: (slice: TypeTooltip<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    type: slice.fields.type,
    tooltipText: slice.fields.tooltipText,
    content: slice.fields.content,
    inlineText: slice.fields.inlineText
  }),
  icon: (slice: TypeIcon<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    icon: slice.fields.icon,
    size: slice.fields.size,
    color: slice.fields.color
  }),
  sectionContainer: (slice: TypeSectionContainer<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    content: slice.fields.content,
    backgroundColor: slice.fields.backgroundColor
  }),
  table: (slice: TypeTable<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    tableHeader: slice.fields.tableHeader,
    tableEntry: slice.fields.tableEntry,
    firstColumnBold: slice.fields.firstColumnBold,
    showSortingIcon: slice.fields.showSortingIcon,
    defaultSorting: slice.fields.defaultSorting
  }),
  linkButton: (slice: TypeLinkButton<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    children: slice.fields.hyperlinkText,
    variant: slice.fields.variant,
    color: slice.fields.color,
    isIconButton: slice.fields.isIconButton,
    insertIcon: slice.fields.insertIcon,
    openInNewWindow: slice.fields.openInNewWindow,
    href: slice.fields.link
  }),
  drawer: (slice: TypeDrawer<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    content: slice.fields.content,
    drawerTag: slice.fields.drawerTag
  }),
  accordion: (slice: TypeAccordion<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    heading: slice.fields.heading,
    content: slice.fields.content,
    hasBottomBorder: slice.fields.hasBottomBorder ?? false
  }),
  workshopAndWebinarSection: (
    slice: TypeWorkshopAndWebinarSection<undefined, 'en-us'>
  ) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    events: slice.fields.events
  }),
  customNavigationTabSection: (
    slice: TypeCustomNavigationTabSection<undefined, 'en-us'>
  ) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    navTabs: slice.fields.navTabs
  }),
  spacer: (slice: TypeSpacer<undefined, 'en-us'>) => ({
    id: slice.sys.id,
    title: slice.fields.title,
    spacing: slice.fields.spacing
  })
};

export type GenericSlice =
  | TypeTwoColumnWithMedia<undefined, 'en-us'>
  | TypeColumnsText<undefined, 'en-us'>
  | TypeBanner<undefined, 'en-us'>
  | TypeDownloadCard<undefined, 'en-us'>
  | TypeText<undefined, 'en-us'>
  | TypeGridContainer<undefined, 'en-us'>
  | TypeDivider<undefined, 'en-us'>
  | TypeCard<undefined, 'en-us'>
  | TypeTooltip<undefined, 'en-us'>
  | TypeIcon<undefined, 'en-us'>
  | TypeSectionContainer<undefined, 'en-us'>
  | TypeTable<undefined, 'en-us'>
  | TypeLinkButton<undefined, 'en-us'>
  | TypeDrawer<undefined, 'en-us'>
  | TypeAccordion<undefined, 'en-us'>
  | TypeWorkshopAndWebinarSection<undefined, 'en-us'>
  | TypeCustomNavigationTabSection<undefined, 'en-us'>
  | TypeSpacer<undefined, 'en-us'>;

export const getSliceId = (slice: GenericSlice) =>
  slice.sys.contentType?.sys?.id;

export default function SliceFactory({ slice }: { slice: GenericSlice }) {
  const sliceID = getSliceId(slice);

  if (!sliceID) {
    clientSide.warn('SliceFactory: No slice ID found');
    return null;
  }

  const SliceComponent = sliceMap[sliceID];
  const fieldMapper = fieldMap[sliceID];
  const props = fieldMapper(slice);

  return <SliceComponent {...props} />;
}
