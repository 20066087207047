import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  InsertIcon,
  richTextOptions
} from 'components/v2/contentful/richText/richTextOptions';
import { Document } from '@contentful/rich-text-types';
import { CONTAINER_MAX_WIDTH, RobotoFlex } from 'styles/theme';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import { useEffect, useMemo, useState } from 'react';
import Icon, { IconNames } from 'components/v2/atomic/icon/Icon';
import {
  IconNamesRegular,
  IconNamesXSmall
} from 'components/v2/atomic/icon/Icons';
import { usePathname, useSearchParams } from 'next/navigation';
import Drawer from 'components/v2/atomic/drawer/Drawer';
import Accordion from 'components/v2/slices/accordion/Accordion';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Button from 'components/v2/atomic/button/Button';
import CustomNavigationTab, {
  TabData
} from 'components/v2/slices/customNavigationTab/CustomNavigationTab';
import LoadMoreGrid from 'components/v2/slices/loadMoreGrid/LoadMoreGrid';
import StatusIndicator from 'components/v2/atomic/statusIndicator/StatusIndicator';
import CardsToDisplay from 'components/cardsToDisplay/CardsToDisplay';
import { BaseStyles } from 'theme-ui';
import SliceFactory, { GenericSlice, getSliceId } from '../SliceFactory';
import { getBGColor } from '../../services/helpers';

interface TableCell {
  fields: {
    tableHeader: string;
    cellContent?: string;
  };
}

interface TableRow {
  rowTitle?: string;
  tableRow: TableCell[];
}

interface TableEntry {
  fields: TableRow;
  sys: {
    id: string;
  };
}

export type Tabs = 'in-person' | 'webinar';

export type EventFields = {
  fields: {
    id: string;
    title: string;
    date: string;
    time: string;
    address: string;
    eventDetails: string;
    locationType: string;
    webinarLink: string;
    registrationLink: string;
    isOpenRSVP: boolean;
  };
  sys: {
    id: string;
  };
};

export interface CustomNavigationTabDataProps {
  fields: {
    navTab: string;
    navTabIcon: InsertIcon;
    navTabIsDisabled: boolean;
    id: string;
    navTabContent: GenericSlice[];
  };
}

export const CustomNavigationTabSection = ({
  navTabs
}: {
  navTabs: CustomNavigationTabDataProps[];
}) => {
  const tabData: TabData[] = navTabs.map(tab => ({
    id: tab.fields.navTab
      .toLowerCase()
      .split(' ')
      .join('-'),
    label: tab.fields.navTab,
    icon: tab.fields.navTabIcon.fields.iconName,
    disabled: tab.fields.navTabIsDisabled,
    hideIcon: false
  }));

  const navTabItemsWithContent = navTabs.map(tab => ({
    ...tab,
    navTabId: tab.fields.navTab
      .toLowerCase()
      .split(' ')
      .join('-')
  }));

  const [activeTab, setActiveTab] = useState(tabData[0].id);
  const [activeTabContent, setActiveTabContent] = useState<
    GenericSlice[] | undefined
  >(navTabs[0].fields.navTabContent);

  useEffect(() => {
    const getContent = navTabItemsWithContent.find(
      tab => tab.navTabId === activeTab
    )?.fields.navTabContent;
    setActiveTabContent(getContent);

    // Only need 1 dependency to check if tabs are switch to prevent multiple re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <div
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          ul: { paddingLeft: 0, li: { listStyleType: 'none' } }
        }}
      >
        <CustomNavigationTab
          tabData={tabData}
          onTabChange={(id: string) => setActiveTab(id)}
        />
      </div>
      <div
        sx={{
          marginTop: '2rem'
        }}
      >
        {activeTabContent?.map(slice => (
          <SliceFactory key={slice.sys.id} slice={slice} />
        ))}
      </div>
    </>
  );
};

export const WorkshopAndWebinarSlice = ({
  events
}: {
  events: EventFields[];
}) => {
  const [selectedEvent, setSelectedEvent] = useState<EventFields | null>(null);
  const [selectedTab, setSelectedTab] = useState<Tabs>('in-person');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const tabData = [
    {
      label: 'Workshops',
      icon: IconNamesRegular.PRESENTATION,
      id: 'in-person',
      disabled: false,
      hideIcon: false
    },
    {
      label: 'Webinars',
      icon: IconNamesRegular.TV,
      id: 'online',
      disabled: false,
      hideIcon: false
    }
  ];

  return (
    <>
      <div sx={{ py: 4 }}>
        <CustomNavigationTab tabData={tabData} onTabChange={setSelectedTab} />
      </div>
      <div>
        {events ? (
          <LoadMoreGrid
            id="id"
            content={CardsToDisplay({
              events,
              selectedTab,
              setSelectedEvent,
              setIsDrawerOpen
            })}
            buttonText="Load more"
          />
        ) : null}
      </div>
      <Drawer
        sx={{
          fontFamily: RobotoFlex.style.fontFamily
        }}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        isActionButtonsEnabled={false}
      >
        <div>
          <h2
            sx={{
              fontFamily: RobotoFlex.style.fontFamily,
              fontSize: 'large',
              fontWeight: 'bold'
            }}
          >
            {selectedEvent?.fields.title}
          </h2>
          <StatusIndicator
            id="open-rsvp-tag"
            intent="success"
            text="Open RSVP"
            type="tag"
          />
          <div className="flex" sx={{ mt: 3 }}>
            <Icon icon={IconNames.CALENDAR} />
            <p sx={{ ml: 2, fontFamily: RobotoFlex.style.fontFamily }}>
              {selectedEvent?.fields.date}
            </p>
          </div>
          <div className="flex">
            <Icon icon={IconNames.CLOCK} />
            <p sx={{ ml: 2, fontFamily: RobotoFlex.style.fontFamily }}>
              {selectedEvent?.fields.time ? selectedEvent?.fields.time : 'TBD'}
            </p>
          </div>
          <div className="flex">
            <Icon icon={IconNames.LOCATION} />
            <p sx={{ ml: 2, fontFamily: RobotoFlex.style.fontFamily }}>
              {selectedEvent?.fields.address
                ? selectedEvent?.fields.address
                : 'TBD'}
            </p>
          </div>
        </div>
        {selectedEvent?.fields.eventDetails ? (
          <h3 sx={{ fontFamily: RobotoFlex.style.fontFamily }}>
            About this event session:
          </h3>
        ) : (
          ''
        )}
        <div sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {selectedEvent?.fields.eventDetails}
        </div>
        <Button
          id="register-button"
          onClick={() =>
            window.open(selectedEvent?.fields.registrationLink, '_blank')
          }
        >
          Register Now
        </Button>
      </Drawer>
    </>
  );
};

export const AccordionSlice = ({
  id,
  heading,
  content,
  hasBottomBorder
}: {
  id: string;
  heading: string;
  content: Document;
  hasBottomBorder: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Accordion
      sxOverride={
        hasBottomBorder
          ? {
              borderBottom: '1px solid',
              borderColor: 'grey60'
            }
          : {}
      }
      accordions={[
        {
          heading: t(heading),
          id,
          children: (
            <>
              <div>{documentToReactComponents(content, richTextOptions)}</div>
            </>
          )
        }
      ]}
    />
  );
};

export const DynamicDrawerSlice = ({
  title,
  content,
  drawerTag
}: {
  title: string;
  content: GenericSlice[];
  drawerTag: string;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const searchParams = useSearchParams();
  const router = useRouter();
  const currentPathname = usePathname();
  useEffect(() => {
    const search = searchParams.get(drawerTag);
    if (search === 'true') {
      setIsDrawerOpen(true);
    }
  }, [searchParams, drawerTag]);
  return (
    <Drawer
      sx={{
        fontFamily: RobotoFlex.style.fontFamily
      }}
      stickyContent={
        <>
          <h2
            sx={{
              fontFamily: RobotoFlex.style.fontFamily,
              fontSize: 'large',
              fontWeight: 'bold'
            }}
          >
            {title}
          </h2>
          {content ? (
            <div>
              {content.map(slice => (
                <SliceFactory key={slice.sys.id} slice={slice} />
              ))}
            </div>
          ) : null}
        </>
      }
      isActionButtonsEnabled={false}
      isOpen={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
        router.replace(currentPathname, undefined, { shallow: true });
      }}
    />
  );
};

export const TextSlice = ({
  description,
  headline,
  HeaderType = 'h3'
}: {
  description: Document;
  headline: string;
  HeaderType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}) => (
  <BaseStyles>
    <HeaderType sx={{ margin: '0 0 16px 0', color: 'brandNavy100' }}>
      {headline}
    </HeaderType>
    <p>{documentToReactComponents(description, richTextOptions)}</p>
  </BaseStyles>
);

const getGridFormat = (format: string) => {
  if (format === '1xn') {
    return 'repeat(1, 1fr)';
  }
  if (format === '2xn') {
    return 'repeat(2, 1fr)';
  }
  if (format === '3xn') {
    return 'repeat(3, 1fr)';
  }
  return 'not valid format';
};

export const GridContainerSlice: React.FC<{
  content: GenericSlice[];
  gridFormat: string;
  hasPadding: boolean;
}> = ({ content, gridFormat, hasPadding }) => {
  let gridTemplateCols = null;
  const breakpoint = useBreakpoint();

  // Super specific case for when there is only a text and download card sharing a grid container
  if (
    content.length === 2 &&
    getSliceId(content[0]) === 'text' &&
    getSliceId(content[1]) === 'downloadCard'
  ) {
    return (
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '2rem',
          padding: hasPadding ? '2rem 0 2rem' : '0'
        }}
      >
        <div sx={{ gridColumn: '1 / span 2' }}>
          <SliceFactory key={content[0].sys.id} slice={content[0]} />
        </div>
        <SliceFactory key={content[1].sys.id} slice={content[1]} />
      </div>
    );
  }

  if (breakpoint.isLessThanSmallMin) {
    gridTemplateCols = 'repeat(1, 1fr)';
  } else if (breakpoint.isBetweenSmallAndXLarge) {
    const gridCols = getGridFormat(gridFormat);
    gridTemplateCols = gridCols;
  }

  return (
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: gridTemplateCols,
        gap: '2rem',
        padding: hasPadding ? '2rem 0 2rem' : '0'
      }}
    >
      {content.map(slice => {
        if (
          getSliceId(slice) === 'downloadCard' ||
          getSliceId(slice) === 'card' ||
          getSliceId(slice) === 'text'
        ) {
          return <SliceFactory key={slice.sys.id} slice={slice} />;
        }
        return null;
      })}
    </div>
  );
};

export const SectionContainer = ({
  content,
  backgroundColor
}: {
  content: GenericSlice[];
  backgroundColor: string;
}) => (
  <div
    sx={{
      backgroundColor: getBGColor(backgroundColor),
      padding: '2rem',
      margin: '0'
    }}
  >
    <div sx={{ maxWidth: CONTAINER_MAX_WIDTH, margin: '0 auto' }}>
      {content.map(slice => (
        <SliceFactory key={slice.sys.id} slice={slice} />
      ))}
    </div>
  </div>
);

type SortOrder = 'asc' | 'desc';

export const Table = ({
  tableEntry,
  tableHeader,
  firstColumnBold,
  showSortingIcon,
  defaultSorting = 'asc'
}: {
  tableEntry: TableEntry[];
  tableHeader: string;
  firstColumnBold: boolean;
  showSortingIcon: boolean;
  defaultSorting: 'asc' | 'desc';
}) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>(defaultSorting);
  const breakpoint = useBreakpoint();
  const headers = useMemo(
    () => tableHeader.split('|').map(item => item.trim()),
    [tableHeader]
  );

  const sortedTableEntry = useMemo(
    () =>
      [...tableEntry].sort((a, b) => {
        if (a.fields.tableRow === undefined) return 1;
        if (b.fields.tableRow === undefined) return -1;
        const aHeader =
          a.fields.tableRow.find(row => row.fields.tableHeader === headers[0])
            ?.fields.cellContent ?? '';
        const bHeader =
          b.fields.tableRow.find(row => row.fields.tableHeader === headers[0])
            ?.fields.cellContent ?? '';

        return sortOrder === 'asc'
          ? aHeader.localeCompare(bHeader)
          : bHeader.localeCompare(aHeader);
      }),
    [tableEntry, sortOrder, headers]
  );

  const dataToDisplay = showSortingIcon ? sortedTableEntry : tableEntry;

  return (
    <div
      sx={{
        maxWidth: 'calc(100vw - 3rem)',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'grey60',
        marginBottom: '3rem'
      }}
    >
      <div
        sx={{
          width: '100%',
          overflowX: 'auto',
          height: '54rem',
          overflowY: 'auto'
        }}
      >
        <table
          sx={{
            borderCollapse: 'collapse',
            width: '100%'
          }}
        >
          <thead>
            {headers.map((header: string, index: number) => (
              <th
                sx={{
                  color: 'white',
                  borderBottom: '1px solid',
                  borderBottomColor: 'grey60',
                  padding: '1rem',
                  whiteSpace: 'pre-wrap',
                  overflow: 'hidden',
                  textAlign: 'left',
                  position: 'sticky',
                  top: 0,
                  zIndex: 5,
                  backgroundColor: 'extendedBlue100',
                  borderTop: '1px solid',
                  borderTopColor: 'extendedBlue100',

                  '&:first-of-type': {
                    borderRight: '1px solid',
                    borderRightColor: 'grey60',
                    position: breakpoint.isLessThanLargeMin
                      ? 'sticky'
                      : undefined,
                    left: 0,
                    zIndex: 6
                  }
                }}
                // there is no unique key for the header, so we use the index
                // eslint-disable-next-line react/no-array-index-key
                key={`${header}-${index}`}
                onClick={() => {
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                }}
                data-testid={`sort-icon-${sortOrder}`}
              >
                {header}
                {index === 0 && showSortingIcon && (
                  <Icon
                    icon={
                      sortOrder === 'asc'
                        ? IconNamesXSmall.ARROW_UP
                        : IconNamesXSmall.ARROW_DOWN
                    }
                    color="white"
                    wrapper="span"
                    sx={{
                      display: 'inline-block',
                      verticalAlign: 'sub',
                      pl: '1rem'
                    }}
                  />
                )}
              </th>
            ))}
          </thead>
          <tbody>
            {dataToDisplay.map(entry => (
              <tr
                key={entry.fields.rowTitle}
                data-testid={`table-row-${entry.sys.id}`}
              >
                {headers.map((header, index) => {
                  const cellContent = entry.fields.tableRow.find(
                    cell => cell.fields.tableHeader.trim() === header
                  );

                  return (
                    <td
                      // there is no unique key for the header, so we use the index
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${cellContent?.fields.cellContent}-${index}`}
                      sx={{
                        borderBottom: '1px solid',
                        borderBottomColor: 'grey60',
                        padding: '1rem',
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textAlign: 'left',
                        backgroundColor: 'white',

                        '&:first-of-type': {
                          borderRight: '1px solid',
                          borderRightColor: 'grey60',
                          position: breakpoint.isLessThanLargeMin
                            ? 'sticky'
                            : 'static',
                          left: 0,
                          zIndex: 4,
                          backgroundColor: 'white',
                          fontWeight: firstColumnBold ? 'bold' : 'normal'
                        }
                      }}
                    >
                      {cellContent
                        ? cellContent.fields.cellContent?.trim()
                        : ''}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
