/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Collapse } from '@blueprintjs/core';
import Icon, { IconNames } from 'components/v2/atomic/icon/Icon';
import { useState } from 'react';
import { ThemeUICSSObject } from 'theme-ui';

interface AccordionItem {
  heading: string;
  id: string;
  children?: React.ReactNode | JSX.Element;
  isOpen?: boolean;
  onChange?: (isOpen: boolean) => void;
}

export interface AccordionProps {
  accordions: AccordionItem[];
  allowSingleOpen?: boolean;
  headingFontSize?: 'small' | 'medium';
  backgroundColor?: string;
  sxOverride?: ThemeUICSSObject;
}

const Accordion: React.FC<AccordionProps> = ({
  accordions,
  allowSingleOpen = false,
  headingFontSize = 'medium',
  backgroundColor = 'white',
  sxOverride
}) => {
  const [accordionOpenState, setAccordionOpenState] = useState(
    accordions.map(acc => (acc.isOpen !== undefined ? acc.isOpen : false))
  );

  const toggleAccordion = (accordionIndex: number) => {
    setAccordionOpenState(prevStates => {
      if (allowSingleOpen) {
        return prevStates.map((state, i) =>
          i === accordionIndex ? !state : false
        );
      }
      const newStates = [...prevStates];
      newStates[accordionIndex] = !newStates[accordionIndex];
      return newStates;
    });
  };

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sxOverride
      }}
    >
      {accordions.map((accordion, index) => (
        <div
          id={accordion.id}
          key={accordion.id}
          sx={{
            backgroundColor: accordionOpenState[index]
              ? 'extendedBlue25'
              : backgroundColor,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '&:hover': {
              backgroundColor: 'extendedBlue25'
            },
            fontSize: headingFontSize,
            fontStyle: 'normal',
            lineHeight: headingFontSize === 'small' ? 'body' : 'medium'
          }}
        >
          <div
            tabIndex={0}
            role="button"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              fontWeight: 'bold',
              cursor: 'pointer',
              color: 'brandNavy100',
              padding: '1.25rem',
              '@media (max-width: 768px)': {
                padding: '1rem'
              },
              width: '100%',
              borderTop: '1px solid',
              borderColor: 'grey60',
              '&:focus': {
                outline: 'none'
              },
              '&:focus-visible': {
                outline: '2px solid',
                outlineColor: 'extendedBlue100',
                outlineOffset: '-2px'
              }
            }}
            onClick={() => toggleAccordion(index)}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter') {
                toggleAccordion(index);
              }
            }}
          >
            <Icon
              icon={
                accordionOpenState[index]
                  ? IconNames.DELETE_MINUS
                  : IconNames.ADD
              }
              sx={{
                borderRadius: '50%',
                backgroundColor: 'white',
                padding: '0.5rem'
              }}
            />
            {accordion.heading}
          </div>
          <Collapse isOpen={accordionOpenState[index]}>
            <div
              sx={{
                padding: accordionOpenState[index]
                  ? '1rem 2rem 2rem 4.5rem'
                  : '0rem',
                fontSize: 'small',
                '@media (max-width: 768px)': {
                  padding: accordionOpenState[index]
                    ? '1rem 1.5rem 1.5rem 4rem'
                    : '0rem'
                }
              }}
            >
              {accordion.children}
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
