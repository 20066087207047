import { ThemeUICSSObject } from 'theme-ui';
import { addLeadingDot } from 'utils/theme-ui/formatImages';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import Card from 'components/v2/atomic/card/Card';
import { TypeCardFields } from 'types/contentful-types';
import React, { useMemo } from 'react';
import { CONTAINER_MAX_WIDTH } from 'styles/theme';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { getBGColor } from 'components/v2/contentful/services/helpers';

export interface BannerProps {
  showOverlayGraphic?: boolean;
  copyBackgroundColour?:
    | 'darkOrange'
    | 'lightOrange'
    | 'darkBlue'
    | 'lightBlue'
    | 'darkGreen'
    | 'lightGreen'
    | 'darkYellow'
    | 'lightYellow'
    | 'darkRed'
    | 'lightRed'
    | 'darkGrey'
    | 'lightGrey'
    | 'white';
  backgroundImageUrl?: string;
  id: string;
  graphicOverlayWidth?: string;
  graphicOverlayAlignmentX?: 'left' | 'center' | 'right';
  graphicOverlayAlignmentY?: 'top' | 'center' | 'bottom';
  bannerHeight: string;
  captionText?: string;
  captionColour?: string;
  children?: React.ReactNode | React.ReactNode[] | string;
  sx?: ThemeUICSSObject;
  centerContents?: boolean;
  backgroundImagePosition?: string;
  bannerTitle?: string;
  cardProps?: React.ComponentProps<typeof Card> & TypeCardFields;
  cardPosition?: 'left' | 'right';
  overlaySx?: ThemeUICSSObject;
  backgroundPositionX?: string;
  backgroundPositionY?: string;
  isMainBanner?: boolean;
}

const Banner = ({
  copyBackgroundColour = 'darkBlue',
  backgroundImageUrl,
  showOverlayGraphic = true,
  bannerHeight,
  graphicOverlayWidth,
  graphicOverlayAlignmentX = 'left',
  graphicOverlayAlignmentY = 'center',
  captionText,
  captionColour = 'white',
  children,
  sx,
  centerContents = false,
  backgroundPositionX,
  backgroundPositionY,
  bannerTitle,
  cardProps,
  cardPosition,
  id,
  overlaySx,
  isMainBanner
}: BannerProps) => {
  const breakpoint = useBreakpoint();
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const bannerSectionId = useMemo(
    () =>
      bannerTitle
        ? bannerTitle
            .toLowerCase()
            .replace(/[^\w\s]/gi, '')
            .split(' ')
            .join('-')
        : '',
    [bannerTitle]
  );

  const isContentfulEnabled = isEnabled(
    featureFlags,
    currentFlags.CONTENTFUL_PAGES
  );

  let backgroundSizeValue;
  if (graphicOverlayWidth) {
    backgroundSizeValue = graphicOverlayWidth;
  } else if (breakpoint.isLessThanSmallMin) {
    backgroundSizeValue = '100%';
  } else if (breakpoint.isLessThanMediumMin) {
    backgroundSizeValue = '75%';
  } else {
    backgroundSizeValue = '60%';
  }

  const height =
    bannerHeight ||
    (breakpoint.isLessThanSmallMin
      ? '21.875rem'
      : breakpoint.isLessThanMediumMin
      ? '39rem'
      : '28rem');

  const cardPositioning = () => {
    if (breakpoint.isLessThanSmallMin) {
      return '0 auto';
    }
    if (cardPosition === 'right') {
      return '0 0 0 auto';
    }
    return '0 auto 0 0';
  };

  return (
    <div
      sx={{
        paddingBottom:
          isContentfulEnabled && cardProps && breakpoint.isLessThanSmallMin
            ? '20rem'
            : '0',
        backgroundColor: getBGColor(copyBackgroundColour)
      }}
    >
      <div
        id={bannerTitle ? bannerSectionId : id}
        data-testid={id}
        sx={{
          height: backgroundImageUrl === 'https:undefined' ? '150px' : height,
          zIndex: 1,
          overflow: 'visible',
          position: 'relative',
          backgroundColor: getBGColor(copyBackgroundColour),
          backgroundImage: backgroundImageUrl
            ? `url(${backgroundImageUrl})`
            : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionY: backgroundPositionY ?? '50%',
          backgroundPositionX: backgroundPositionX ?? '50%',
          ...sx
        }}
      >
        {showOverlayGraphic && (
          <div
            sx={{
              height:
                backgroundImageUrl === 'https:undefined' ? '150px' : height,
              backgroundImage: `url(${addLeadingDot(
                '/images/backgrounds/squiggly.svg'
              )})`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: graphicOverlayAlignmentX,
              backgroundPositionY: graphicOverlayAlignmentY,
              backgroundSize: backgroundSizeValue,
              '@media (max-width: 767px)': {
                p: '24px'
              },
              position: 'absolute',
              width: ['100%', '100%', '150%', '150%'],
              zIndex: 20,
              ...overlaySx
            }}
          />
        )}
        <div
          sx={{
            height: backgroundImageUrl === 'https:undefined' ? '150px' : height,
            width: '100%',
            margin: '0 auto',
            maxWidth: CONTAINER_MAX_WIDTH,
            p: '32px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent:
              centerContents || !isMainBanner ? 'center' : undefined,
            zIndex: 30,
            ...sx
          }}
        >
          {cardProps && (
            <div
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: breakpoint.isLessThanSmallMin ? '100%' : '50%',
                boxShadow: '0 0 0.937rem 0 rgba(0, 0, 0, 0.2)',
                margin: cardPositioning,
                position:
                  isContentfulEnabled &&
                  breakpoint.isLessThanSmallMin &&
                  'relative',
                top:
                  isContentfulEnabled &&
                  breakpoint.isLessThanSmallMin &&
                  '15rem'
              }}
            >
              <Card
                id={cardProps.id}
                accent={cardProps.accent}
                accentColor={cardProps.accentColor}
                title={cardProps.title}
                noPadding={cardProps.noPadding}
                description={cardProps.description}
                bannerCard
              />
            </div>
          )}
          {children && (
            <div
              sx={{
                m: 0,
                p: 0,
                zIndex: 40
              }}
            >
              {children}
            </div>
          )}
          {bannerTitle && !cardProps && isMainBanner && (
            <div
              sx={{
                m: 0,
                p: 0
              }}
            >
              <h1
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  zIndex: '45'
                }}
              >
                {bannerTitle}
              </h1>
            </div>
          )}
          {bannerTitle && !cardProps && !isMainBanner && (
            <div>
              <h2
                sx={{
                  zIndex: '45',
                  position: 'relative',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  fontSize: '2.375rem !important'
                }}
              >
                {bannerTitle}
              </h2>
            </div>
          )}
          {captionText && (
            <div
              sx={{
                alignSelf: 'flex-end',
                justifySelf: 'flex-end',
                mt: 'auto',
                color: captionColour
              }}
            >
              {captionText}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
