import { BaseStyles } from 'theme-ui';
import RichText from 'components/richText/RichText';

interface ColumnContent {
  header: string;
  paragraph: string;
}

export interface ColumnsTextProps {
  HeaderType?: 'h3' | 'h4' | 'h5' | 'h6';
  columnContent: ColumnContent[];
}

const ColumnsText: React.FC<ColumnsTextProps> = ({
  HeaderType = 'h3',
  columnContent
}) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateColumns: `repeat(${columnContent.length}, 1fr)`,
      gap: '64px',
      '@media screen and (max-width: 1110px)': {
        gridTemplateColumns: '1fr',
        gap: '32px'
      }
    }}
  >
    {columnContent.map(column => (
      <div key={column.header}>
        <BaseStyles>
          <HeaderType sx={{ margin: '0 0 16px 0' }}>{column.header}</HeaderType>
          <p
            sx={{
              marginBottom: '0px',
              ol: {
                li: {
                  listStyle: 'decimal'
                },
                paddingInlineStart: '22px !important'
              },
              ul: {
                li: {
                  listStyle: 'disc'
                },
                paddingInlineStart: '22px'
              }
            }}
          >
            <RichText disablePTag content={column.paragraph} />
          </p>
        </BaseStyles>
      </div>
    ))}
  </div>
);

export default ColumnsText;
