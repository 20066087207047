import { useState, useEffect } from 'react';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import Button from 'components/v2/atomic/button/Button';
import Icon, { IconNames } from 'components/v2/atomic/icon/Icon';

type LoadMoreGridProps = {
  id: string;
  content: JSX.Element[];
  buttonText?: string;
};

const LoadMoreGrid = ({
  id,
  content,
  buttonText = 'Load more'
}: LoadMoreGridProps) => {
  const breakpoint = useBreakpoint();
  const numberOfColumns = () => {
    if (breakpoint.isLessThanSmallMin) return 1;
    if (breakpoint.isLessThanMediumMin) return 2;
    return 3;
  };
  const [numberOfCardsToAdd, setNumberOfCardsToAdd] = useState(9);
  const [numberOfCardsShown, setNumberOfCardsShown] = useState(
    numberOfCardsToAdd
  );
  const [contentToDisplay, setContentToDisplay] = useState(
    content.slice(0, numberOfCardsShown)
  );

  useEffect(() => {
    setNumberOfCardsToAdd(breakpoint.isLessThanMediumMin ? 12 : 9);
  }, [breakpoint]);

  useEffect(() => {
    setNumberOfCardsShown(numberOfCardsToAdd);
    setContentToDisplay(content.slice(0, numberOfCardsToAdd));
  }, [content, numberOfCardsToAdd]);

  return (
    <div
      id={id}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${numberOfColumns()}, 1fr)`,
          gridGap: ['2rem', '1.5rem', '1.5rem', undefined]
        }}
      >
        {contentToDisplay.map((card: JSX.Element, i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${id}-${i}`}>{card}</div>
        ))}
      </div>
      {contentToDisplay.length < content.length && (
        <Button
          id={`${id}-load-more-button`}
          onClick={() => {
            setContentToDisplay(
              content.slice(0, numberOfCardsShown + numberOfCardsToAdd)
            );
            setNumberOfCardsShown(numberOfCardsShown + numberOfCardsToAdd);
          }}
          sx={{
            marginTop: '2rem',
            width: ['fit-content', '100%', undefined],
            alignSelf: 'center'
          }}
        >
          {buttonText}
          <Icon icon={IconNames.DOWN} sx={{ marginLeft: '0.5rem' }} />
        </Button>
      )}
    </div>
  );
};

export default LoadMoreGrid;
