import Button from 'components/v2/atomic/button/Button';
import Icon from 'components/v2/atomic/icon/Icon';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import LinkButton from 'components/v2/atomic/linkButton/LinkButton';
import { RichText} from 'components/v2/contentful/richText/richTextOptions';
import { ReactElement } from 'react';
import { BaseStyles } from 'theme-ui';
import { Document } from '@contentful/rich-text-types';
import { getBGColor } from 'components/v2/contentful/services/helpers';


// eslint-disable-next-line prettier/prettier
type Alignment = 'left' | 'center' | 'right' | `${number}%`; // Prettier doesnt support the template literal types yet

export interface TwoColumnWithMediaProps {
  id: string;
  title: string;
  titleSize?: 'h2' | 'h3';
  description: string | ReactElement | Document;
  textAlignment?: 'top' | 'center';
  copyBackgroundColour?:
    | 'blue'
    | 'green'
    | 'yellow'
    | 'grey'
    | 'white'
    | 'none';
  callToAction?: string;
  buttonHandler?: () => void;
  buttonStyle?: 'outline' | 'default';
  buttonIcon?: IconNamesSmall.OPEN_IN_NEW | IconNamesSmall.CHEVRON_RIGHT;
  imageSource: string;
  imageAlt?: string;
  imageFit?: 'cover' | 'contain' | 'fill' | 'none';
  imageVerticalAlignment?: 'top' | 'center' | 'bottom';
  imageHorizontalAlignment?: Alignment;
  imageOnLeft?: boolean;
  callToActionLink?: string;
}

const TwoColumnWithMedia = ({
  id,
  title,
  titleSize = 'h2',
  description,
  textAlignment = 'top',
  callToAction,
  copyBackgroundColour = 'none',
  buttonHandler,
  buttonIcon,
  buttonStyle = 'outline',
  imageSource,
  imageAlt = '',
  imageFit = 'cover',
  imageVerticalAlignment = 'center',
  imageHorizontalAlignment = 'center',
  imageOnLeft = false,
  callToActionLink
}: TwoColumnWithMediaProps) => (
      <div
        sx={{
          display: 'flex',
          flexDirection: [
            imageOnLeft ? 'row-reverse' : 'row',
            'column-reverse',
            null
          ],
          borderColor: 'grey60',
          borderWidth: '1px',
          borderStyle: 'solid',
          minHeight: ['27.45rem', '0', '13.75rem', '19.9rem'],
          backgroundColor: getBGColor(copyBackgroundColour),
        }}
      >
        <div
          sx={{
            width: ['50%', '100%', null],
            padding: ['2rem 3rem', '1.5rem', '2rem', null],
            alignSelf: textAlignment === 'center' ? 'center' : undefined
          }}
        >
          <BaseStyles>
            {titleSize === 'h2' && (
              <h2 sx={{ marginBottom: '1rem' }} data-testid={`${id}-title`}>
                {title}
              </h2>
            )}
            {titleSize === 'h3' && (
              <h3 sx={{ marginBottom: '1rem' }} data-testid={`${id}-title`}>
                {title}
              </h3>
            )}
            <p
              sx={{ marginBottom: callToAction ? '1.5rem !important' : '0rem' }}
              data-testid={`${id}-description`}
            >
              <RichText node={description} />
            </p>
          </BaseStyles>
          {callToAction && !callToActionLink && (
            <Button
              id={`${id}-call-to-action`}
              variant={buttonStyle}
              color={buttonStyle === 'outline' ? 'blue' : 'orange'}
              onClick={buttonHandler}
              sx={{
                width: ['fit-content', '100%', null]
              }}
            >
              {callToAction}
              {buttonIcon && (
                <Icon
                  icon={buttonIcon}
                  sx={{
                    svg: {
                      color: buttonStyle === 'outline' ? 'brandNavy100' : 'white'
                    }
                  }} />
              )}
            </Button>
          )}

          {callToAction && callToActionLink && (
            <LinkButton
              href={callToActionLink}
              id={callToActionLink}
              variant={buttonStyle}
              color={buttonStyle === 'outline' ? 'blue' : 'orange'}
              sxOverride={{
                width: ['fit-content', '100%', null]
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {callToAction}
              {buttonIcon && (
                <Icon
                  icon={buttonIcon}
                  sx={{
                    svg: {
                      color: buttonStyle === 'outline' ? 'brandNavy100' : 'white'
                    }
                  }} />
              )}
            </LinkButton>
          )}
        </div>
        <div
          sx={{
            width: ['50%', '100%', null],
            height: [null, '12.8125rem', null],
            position: 'relative',
            backgroundColor: 'white'
          }}
        >
          <img
            data-testid={`${id}-image`}
            sx={{
              height: '100%',
              position: ['absolute', 'relative', null],
              objectFit: imageFit,
              objectPosition: `${imageHorizontalAlignment} ${imageVerticalAlignment}`
            }}
            src={imageSource}
            alt={imageAlt} />
        </div>
      </div>
    );

export default TwoColumnWithMedia;
