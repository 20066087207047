import Icon, { IconName } from 'components/v2/atomic/icon/Icon';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import { useState } from 'react';

export type TabData = {
  label: string;
  icon?: IconName;
  id: string;
  disabled?: boolean;
  hideIcon: boolean;
  iconCount?: number;
  hideTab?: boolean;
};

export interface CustomNavigationTabProps {
  tabData: TabData[];
  onTabChange?: Function;
  styleOptions?: {
    includeBoxShadow: boolean;
  };
}

export const getRight = (
  iconCount: string,
  breakpoint: ReturnType<typeof useBreakpoint>
) => {
  if (breakpoint.isLessThanSmallMin) {
    if (iconCount.length > 1) return '-7rem';
    return '-6rem';
  }
  if (iconCount.length > 1) return '-3rem';
  return '-2rem';
};

export const getCountText = (count: number) => {
  if (count === 0) return null;
  if (count >= 99) return '99+';
  return count.toString();
};

const CustomNavigationTab = ({
  tabData,
  onTabChange,
  styleOptions = { includeBoxShadow: true }
}: CustomNavigationTabProps) => {
  const breakpoint = useBreakpoint();
  const [activeTab, setActiveTab] = useState(tabData[0]?.id);

  return (
    <div>
      <ul
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: breakpoint.isLessThanSmallMin ? 'column' : 'row'
        }}
      >
        {tabData
          .filter((data: TabData) => !data.hideTab)
          .map((data: TabData) => (
            <li
              key={data.id}
              sx={{
                borderRadius: '5px',
                '&:first-of-type button': {
                  borderRadius: breakpoint.isLessThanSmallMin
                    ? '5px 5px 0px 0px'
                    : '5px 0px 0px 5px'
                },
                '&:last-of-type button': {
                  borderRadius: breakpoint.isLessThanSmallMin
                    ? '0px 0px 5px 5px'
                    : '0px 5px 5px 0px',
                  marginRight: '0'
                },
                '> button': {
                  marginBottom: '0'
                },
                display: 'flex',
                boxShadow: styleOptions?.includeBoxShadow
                  ? '0px 0px 15px 0px rgba(0, 0, 0, 0.20)'
                  : undefined
              }}
            >
              <button
                type="button"
                value={data.id}
                onClick={() => {
                  setActiveTab(data.id);
                  onTabChange?.(data?.id);
                }}
                sx={{
                  backgroundColor:
                    data.id === activeTab ? 'extendedBlue100' : 'white',
                  color: data.id === activeTab ? 'white' : 'grey100',
                  width: '100%',
                  height: breakpoint.isLessThanSmallMin ? '50%' : '100%',
                  border: 'none',
                  padding: breakpoint.isLessThanSmallMin
                    ? '1rem 1.5rem'
                    : '1.5rem 2rem',
                  '&:focus': {
                    color:
                      data.id !== activeTab ? 'extendedBlue100' : undefined,
                    '.icon': {
                      color:
                        data.id !== activeTab && !data.disabled
                          ? 'extendedBlue100'
                          : undefined
                    },
                    outline: 'none'
                  },
                  '&:hover': {
                    color:
                      data.id !== activeTab && !data.disabled
                        ? 'extendedBlue100'
                        : undefined,
                    '.icon': {
                      color:
                        data.id !== activeTab && !data.disabled
                          ? 'extendedBlue100'
                          : undefined
                    }
                  },
                  ':disabled': {
                    backgroundColor: 'white',
                    color: 'grey80',
                    cursor: 'not-allowed'
                  }
                }}
                disabled={data.disabled}
              >
                <div
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {data.icon && !data.hideIcon ? (
                    <div
                      sx={
                        data.iconCount
                          ? {
                              position: 'relative'
                            }
                          : {}
                      }
                    >
                      <Icon
                        icon={data.icon}
                        className="icon"
                        color={
                          data.id === activeTab
                            ? 'white'
                            : data.disabled
                            ? 'grey80'
                            : 'grey100'
                        }
                        sx={{
                          svg: {
                            width: '36px',
                            height: '36px'
                          },
                          mb: '8px',
                          display: breakpoint.isLessThanSmallMin
                            ? 'none'
                            : undefined
                        }}
                      />
                      {data.iconCount && data.iconCount > 0 ? (
                        <span
                          sx={{
                            width:
                              data.iconCount.toString().length > 1
                                ? '2.6rem'
                                : '1.6rem',
                            height: '1.6rem',
                            borderRadius: '50%',
                            color:
                              data.id === activeTab
                                ? 'extendedBlue100'
                                : 'white',
                            backgroundColor:
                              data.id === activeTab
                                ? 'white'
                                : 'extendedBlue100',
                            position: 'absolute',
                            top: '0',
                            right: getRight(
                              data.iconCount.toString(),
                              breakpoint
                            )
                          }}
                          data-testid="icon-count"
                        >
                          {getCountText(data.iconCount)}
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  <div
                    sx={{
                      fontWeight: 'semibold',
                      fontSize: breakpoint.isLessThanSmallMin
                        ? 'small'
                        : 'medium',
                      lineHeight: breakpoint.isLessThanSmallMin
                        ? 'body'
                        : 'medium'
                    }}
                  >
                    {data.label}
                  </div>
                </div>
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CustomNavigationTab;
