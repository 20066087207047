import StatusIndicator from 'components/v2/atomic/statusIndicator/StatusIndicator';
import Icon from 'components/v2/atomic/icon/Icon';
import Card from 'components/v2/atomic/card/Card';
import { IconNamesFileFormat } from 'components/v2/atomic/icon/Icons';
import { FileExtensions } from 'types/file-extensions';
import { ThemeUICSSObject } from 'theme-ui';
import { RichText } from 'components/v2/contentful/richText/richTextOptions';
import { ReactElement } from 'react';
import { Document } from '@contentful/rich-text-types';
import useBreakpoint from 'utils/hooks/useBreakpoint';

interface FileUrl {
  url: string;
  fileSize: string;
}

export interface DownloadCardProps {
  id: string;
  title: string;
  description: Document | string | ReactElement;
  fileType:
    | 'csv'
    | 'doc'
    | 'docx'
    | 'jpeg'
    | 'jpg'
    | 'pdf'
    | 'png'
    | 'xls'
    | 'xlsx'
    | 'xlsm'
    | 'txt';
  englishUrl: FileUrl;
  frenchUrl?: FileUrl;
  optionalTag?: string;
  additionalText?: string;
  width?: string;
  widthMobileView?: string;
  sxOverride?: ThemeUICSSObject;
}

const DownloadCard: React.FC<DownloadCardProps> = ({
  id,
  title,
  description,
  fileType,
  englishUrl,
  frenchUrl,
  optionalTag,
  additionalText,
  width,
  widthMobileView,
  sxOverride = {}
}) => {
  const breakpoint = useBreakpoint();

  const getIcon = () => {
    switch (fileType) {
      case FileExtensions.CSV:
        return IconNamesFileFormat.CSV;
      case FileExtensions.TXT:
        return IconNamesFileFormat.TXT;
      case FileExtensions.DOC:
      case FileExtensions.DOCX:
        return IconNamesFileFormat.DOC;
      case FileExtensions.JPEG:
      case FileExtensions.JPG:
        return IconNamesFileFormat.JPG;
      case FileExtensions.PDF:
        return IconNamesFileFormat.PDF;
      case FileExtensions.PNG:
        return IconNamesFileFormat.PNG;
      case FileExtensions.XLS:
      case FileExtensions.XLSX:
      case FileExtensions.XLSM:
        return IconNamesFileFormat.XLS;
      default:
        return IconNamesFileFormat.PDF;
    }
  };

  return (
    <>
      <Card
        id={id}
        sxOverride={{
          ...sxOverride,
          width: breakpoint.isLessThanSmallMin ? widthMobileView : width
        }}
      >
        {optionalTag && (
          <StatusIndicator
            id="optional-tag"
            intent="info"
            text={optionalTag}
            type="tag"
            data-testid={`${id}-tag`}
            sx={{ mb: '1rem' }}
          />
        )}
        <h3
          sx={{ mb: '1rem', fontFamily: 'Roboto Flex' }}
          data-test-id={`${id}-title`}
        >
          {title}
        </h3>
        <p
          sx={{ mb: '1.5rem', fontFamily: 'Roboto Flex' }}
          data-test-id={`${id}-description`}
        >
          <RichText node={description} />
        </p>
        <div sx={{ display: 'flex' }}>
          <Icon
            icon={getIcon()}
            data-testid={`${id}-formIcon1`}
            wrapper="span"
            sx={{ mr: '0.5rem' }}
          />
          <a
            href={englishUrl.url}
            download={`${title}_EN`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'none',
              mb: frenchUrl ? '1rem' : '0rem'
            }}
            data-test-id={`${id}-enDownloadLink`}
          >
            {additionalText
              ? `${additionalText} - English (${fileType.toLowerCase()} ${
                  englishUrl.fileSize
                })`
              : `English (${fileType.toLowerCase()} ${englishUrl.fileSize})`}
          </a>
        </div>
        {frenchUrl && (
          <div sx={{ display: 'flex', mr: '0.5rem' }}>
            <Icon
              icon={getIcon()}
              data-testid={`${id}-formIcon2`}
              wrapper="span"
              sx={{ mr: '0.5rem' }}
            />
            <a
              href={frenchUrl.url}
              download={`${title}_FR`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none' }}
              data-test-id={`${id}-frDownloadLink`}
            >
              {additionalText
                ? `${additionalText} - Français (${fileType.toLowerCase()} ${
                    frenchUrl.fileSize
                  })`
                : `Français (${fileType.toLowerCase()} ${frenchUrl.fileSize})`}
            </a>
          </div>
        )}
      </Card>
    </>
  );
};

export default DownloadCard;
