export const getBGColor = (backgroundColor: string) => {
  switch (backgroundColor) {
    case 'darkOrange':
      return 'brandOrange50';
    case 'lightOrange':
      return 'brandOrange25';
    case 'brandNavyBlue':
      return 'brandNavy100';
    case 'darkBlue':
      return 'extendedBlue50';
    case 'lightBlue':
    case 'blue':
      return 'extendedBlue25';
    case 'darkGreen':
      return 'extendedGreen50';
    case 'lightGreen':
    case 'green':
      return 'extendedGreen25';
    case 'darkYellow':
      return 'extendedYellow50';
    case 'lightYellow':
    case 'yellow':
      return 'extendedYellow25';
    case 'darkRed':
      return 'extendedRed50';
    case 'lightRed':
      return 'extendedRed25';
    case 'darkGrey':
      return 'grey40';
    case 'lightGrey':
    case 'grey':
      return 'grey20';
    case 'paleGrey':
      return 'grey10';
    case 'white':
      return 'white';
    default:
      return undefined;
  }
};
