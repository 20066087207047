import {
  EventFields,
  Tabs
} from 'components/v2/contentful/sliceFactory/sliceFactoryComponents/sliceFactoryComponents';
import EventsCard from 'components/v2/slices/eventsCard/EventsCard';
import { isFuture, compareAsc, format, parseISO } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';

const ifEventIsPast = (a: string) => {
  const eventDate = new Date(a).getTime();
  return isFuture(eventDate);
};

const CardsToDisplay = ({
  events,
  selectedTab,
  setSelectedEvent,
  setIsDrawerOpen
}: {
  events: EventFields[];
  selectedTab: Tabs;
  setSelectedEvent: Dispatch<SetStateAction<EventFields | null>>;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}) =>
  events
    .filter(event => event.fields.locationType === selectedTab.toLowerCase())
    .filter(a => ifEventIsPast(a.fields.date))
    .sort((a, b) =>
      compareAsc(new Date(a.fields.date), new Date(b.fields.date))
    )
    .map(card => {
      let eventLocation;
      if (!card.fields.address) {
        eventLocation = 'TBD';
      } else if (card.fields.locationType === 'in-person') {
        eventLocation = card.fields.address;
      } else {
        eventLocation = card.fields.webinarLink;
      }
      return (
        <EventsCard
          key={card.sys.id}
          heading={card.fields.title}
          includeOpenRsvpTag={card.fields.isOpenRSVP}
          date={format(parseISO(card.fields.date), 'MMM d, y')}
          time={card.fields.time ? card.fields.time : 'TBD'}
          locationType={card.fields.locationType as 'online' | 'in-person'}
          location={eventLocation}
          id={card.sys.id}
          handleViewDetailsClick={() => {
            setSelectedEvent(card);
            setIsDrawerOpen(true);
          }}
          height="100%"
        />
      );
    });

export default CardsToDisplay;
